
import { Component, Vue } from 'vue-property-decorator';
import googleClient from '../../../../google_client.json';
// import facebookClient from '../../../../facebook_client.json';
import { dispatchAuthUserByGoogle } from '@/store/main/actions';
import { getLocalSessionId } from '@/utils';

@Component({
  components: {
  },
})
export default class AuthGoogle extends Vue {
  public username = '';

  public async mounted() {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      body: JSON.stringify({
        client_id: googleClient.web.client_id,
        client_secret: googleClient.web.client_secret,
        code: this.$router.currentRoute.query.code,
        grant_type: 'authorization_code',
        redirect_uri: googleClient.web.redirect_uris[0],
      }),
    });
    // access_token, expires_in, token_type
    const tokenResponse = await response.json();
    // console.log('tokenResponse', this.tokenResponse);
    const responseUserInfo = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
      headers: {
        authorization: `${tokenResponse.token_type} ${tokenResponse.access_token}`,
      },
    });
    const userInfo = await responseUserInfo.json();
    // console.log('userInfo', userInfo);
    const res = await dispatchAuthUserByGoogle(this.$store, {
        id: userInfo.id,
        name: userInfo.name,
        family_name: userInfo.family_name,
        given_name: userInfo.given_name,
        picture: userInfo.picture,
        locale: userInfo.locale,
        email: userInfo.email,
        verified_email: userInfo.verified_email,
        anon_session_id: getLocalSessionId(),
    });
    if (!res.success) {
      this.$toast.error(this.$t('Failed to authenticate').toString());
    }
    this.$router.push(`/`);
  }
}

